import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchPointCreate(eventId,data) {
  return axios.post(baseUrl + baseApiVersion + `/point/event/${eventId}`, data, {
    headers,
  });
}

export function fetchPointList(params) {
  return axios.get(baseUrl + baseApiVersion + `/point/list`, {
    params,
    headers,
  });
}


export function fetchPointModify(index, eventId, data) {
  return axios.patch(baseUrl + baseApiVersion + `/point/${index}/event/${eventId}`, data,{
    headers,
  });
}

export function fetchPointCheckUpdate(eventId, params) {
  return axios.get(baseUrl + baseApiVersion+ `/point/event/${eventId}/checkUpdate`, {
    params,
    headers,
  });
}

export function fetchPointDelete(index,eventId) {
  return axios.delete(baseUrl + baseApiVersion + `/point/${index}/event/${eventId}`, {
    headers,
  });
}

export function getPointTypeName(type){
  var result = "-"
  switch(type){
    case "Home" :
      result = "point-type-name-home"
      break;
    case "Caution" :
      result = "point-type-name-caution"
      break;
    case "Danger" :
      result = "point-type-name-danger"
      break;
    case "Search" :
      result = "point-type-name-search"
      break;
    case "Target" :
      result = "point-type-name-target"
      break;
    case "Station" :
      result = "point-type-name-station"
      break;
  }
  return result;
}