class MapLineData {
    constructor() {
        //아이디, 사용자 입력
        this.id = null
        //API 의 데이터 리스트, 사용자 입력
        this.locationGroup = []
        //선택 여부 또는 메인, 사용자 입력 혹은 무시
        this.isMain = false
        //라인 보여짐 여부, 사용자 입력 혹은 무시
        this.isShow = false
        //맵에 사용할 아이디, 내부 생성
        this.mapId = null
        //맵에 사용할 칼라, 내부 생성
        this.color = null
        //맵에 사용할 라인, 내부 생성
        this.line = []
    } 
}

export default MapLineData;